import React, { createContext, useReducer } from 'react';

const initialState = {
  filter: 'all',
};

const reducer = (state, action) => {
  return { filter: action.payload };
};

export const SimpleFilterContext = createContext({
  state: initialState,
  dispatch: null,
});

export const SimpleFilterProvider = ({ children }) => {
  const [simpleFilterState, simpleFilterDispatch] = useReducer(
    reducer,
    initialState
  );

  return (
    <SimpleFilterContext.Provider
      value={{ simpleFilterState, simpleFilterDispatch }}
    >
      {children}
    </SimpleFilterContext.Provider>
  );
};
